import React from 'react'
import PropTypes from 'prop-types'

import { useConfirmEmailHook } from './use-confirm-email-hook'
import { ROUTES } from '../../../constants/routes'
import * as S from './confirm-email-styled'

const ConfirmEmailComponent = ({ email, code, apiUrl }) => {
  const {
    isLoading,
    showSuccessMessage,
    errorMessage
  } = useConfirmEmailHook({ email, code, apiUrl });

  return (
    <S.Container>
      {isLoading && <S.LoadingIcon />}

      {showSuccessMessage && (
        <S.SuccessMessageContainer>

          <S.LargeText>Thanks for signing up</S.LargeText>

          <S.MediumText>Your e-mail address is confirmed, enjoy!</S.MediumText>

          <S.HomeButton onClick={() => window.location.href = ROUTES.home.path}>
            Back to Home
          </S.HomeButton>

        </S.SuccessMessageContainer>
      )}

      {errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}

    </S.Container>
  )
}

ConfirmEmailComponent.propTypes = {
  email: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  apiUrl: PropTypes.string.isRequired,
}

export { ConfirmEmailComponent }