import React, { useState, useEffect }  from 'react'

import Layout from '../components/common/layout/layout-component'
import SEO from '../components/common/seo'
import { ConfirmEmail } from '../components/pages/confirm-email/confirm-email-container'

const ConfirmPage = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setEmail(urlParams.get('email'));
    setCode(urlParams.get('code'));
  }, [])

  return (
    <Layout>

      <SEO
        title="Confirm Email"
        keywords={['confirm', 'verify', 'email']}
      />

      <ConfirmEmail
        email={email}
        code={code}
      />

    </Layout>
  )
}

export default ConfirmPage;