import { useState, useEffect } from 'react'

import { makeConfirmEmailRequest } from './confirm-email-networking'

export const useConfirmEmailHook = ({ email, code, apiUrl }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('An error occurred.');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setErrorMessage('');

    if (!email || !code) {

      setErrorMessage('Something is wrong with the URl.');
      setIsLoading(false);

    } else {

      makeConfirmEmailRequest({ email, code, apiUrl })
        .then(response => response.json())
        .then(response => {
          if (response.error) {
            throw new Error(response.error);
          } else {
            setShowSuccessMessage(true);
            setIsLoading(false);
          }
        })
        .catch(err => {
          console.error(err);
          setIsLoading(false);
          setErrorMessage(`${err.message} Check the URL and refresh the page to try again.`);
        })

    }
  }, [email, code, apiUrl]);

  return {
    isLoading,
    showSuccessMessage,
    errorMessage,
  }
}