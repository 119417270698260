import styled from 'styled-components'

import { MainTitle, BodyText } from '../../common/text-styled'
import { Button } from '../../common/inputs/button-styled'
import { LoadingSpinner } from '../../common/loading-spinner/loading-spinner-component'
import { media } from '../../../constants/media'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 50px;
  font-family: ${props => props.theme.fonts.poppins};
`;

export const LoadingIcon = styled(LoadingSpinner)`
`;

export const SuccessMessageContainer = styled.div`
`;

export const LargeText = styled(MainTitle)`
  text-align: center;
  @media ${media.medium.query} {
    text-align: left;
  }
`;

export const MediumText = styled(BodyText)`
  font-weight: bold;
  text-align: center;
  @media ${media.medium.query} {
    text-align: left;
  }
`;

export const ErrorMessage = styled.div`
  font-family: ${props => props.theme.fonts.poppins};
  font-size: 3.0rem;
  color: ${props => props.theme.concepts.errorColor};
  text-align: center;
`;

export const HomeButton = styled(Button)`
`;