export const makeConfirmEmailRequest = ({
  apiUrl,
  email,
  code,
}) => {
  const CONFIRM_EMAIL_ENDPOINT = `${apiUrl}/confirm`;

  return fetch(`${CONFIRM_EMAIL_ENDPOINT}?email=${email}&code=${code}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  })
}