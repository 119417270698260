import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { ConfirmEmailComponent } from './confirm-email-component'

export const ConfirmEmail = (props) => (
  <StaticQuery
    query={confirmEmailQuery}
    render={data =>
      <ConfirmEmailComponent
        apiUrl={data.site.siteMetadata.awsApiGatewayUrl}
        {...props} 
      />
    }
  />
)

const confirmEmailQuery = graphql`
  query confirmEmailQuery {
    site {
      siteMetadata {
        awsApiGatewayUrl
      }
    }
  }
`;